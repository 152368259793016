(function() {

	// Send CSRF token on every AJAX request
	$.ajaxSetup({
		headers: {
			'X-CSRF-Token': $('meta[name="_token"]').attr('content')
		}
	});

	// Fancybox
	$('.fancybox').fancybox();

	// Add another photo
	$('.add-another-photo').click(function(e) {

		e.preventDefault();

		var $photoInput = '<input type="file" name="photos[]" required class="form__file">';

		$(this).parent().find('.form__multiple').append($photoInput);

	});

	// Vote
	$('.not-voted').click(function() {

		var $responseElement = $('.ajax-response');
		var $element = $(this);
		var id = $element.data('id');

		$.ajax({
			method: 'POST',
			url: '/photo/vote',
			data: {
				album_id: id
			},
			beforeSend: function() {
				$responseElement.addClass('loading').html('<i class="fa fa-cog fa-spin"></i> Loading...').show();
			},
			success: function(response) {
				$responseElement.removeClass('loading');

				if (response.success) {
					var votes = parseInt($element.find('.vote__number').text());

					$element.removeClass('not-voted').addClass('voted').find('.vote__number').text(votes + 1);

					$responseElement.addClass('success').html('<i class="fa fa-check"></i> ' + response.message).delay(5000).fadeOut(400, function() {
						$(this).removeClass('success');
					});
				}
				else {
					$responseElement.addClass('error').html('<i class="fa fa-times"></i> ' + response.message).delay(5000).fadeOut(400, function() {
						$(this).removeClass('error');
					});
				}
			}
		});
	});

	// Vote final
	$('.not-voted--final').click(function() {

		var $responseElement = $('.ajax-response');
		var $element = $(this);
		var id = $element.data('id');

		$.ajax({
			method: 'POST',
			url: '/photo/final-vote',
			data: {
				album_id: id
			},
			beforeSend: function() {
				$responseElement.addClass('loading').html('<i class="fa fa-cog fa-spin"></i> Loading...').show();
			},
			success: function(response) {
				$responseElement.removeClass('loading');

				if (response.success) {
					var votes = parseInt($element.find('.vote__number').text());

					$element.removeClass('not-voted--final').addClass('voted').find('.vote__number').text(votes + 1);

					$responseElement.addClass('success').html('<i class="fa fa-check"></i> ' + response.message).delay(5000).fadeOut(400, function() {
						$(this).removeClass('success');
					});
				}
				else {
					$responseElement.addClass('error').html('<i class="fa fa-times"></i> ' + response.message).delay(5000).fadeOut(400, function() {
						$(this).removeClass('error');
					});
				}
			}
		});
	});

	// Filter
	$('#filter').change(function() {
		var id = $(this).find(':selected').data('id');

		window.location.href = '/archive?week=' + id;
	});

})();